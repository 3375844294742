<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" width="100%" max-width="1100" class="transparent mb-5">
        <v-card-title flat class="transparent">
          <div class="d-flex">
            <h1 class="headline">{{ isEdit ? $t('common.edit') : $t('medicalTests.new_rx_protocol') }}</h1>
          </div>
        </v-card-title>
        <v-card>
          <v-card-text>
            <form>
              <v-layout wrap>
                <v-flex xs12 sm4 lg3>
                  <v-text-field
                    data-test="test-rx-input-code"
                    v-model.trim="medicalTest.code"
                    dense
                    outlined
                    type="text"
                    class="mr-sm-1"
                    :label="$t('medicalTests.test_type_code') + ' *'"
                    :error-messages="codeValidationError"
                    :disabled="isDisabled"
                  />
                </v-flex>
                <v-flex xs12 sm8 lg9>
                  <v-text-field
                    data-test="test-rx-input-description"
                    v-model.trim="medicalTest.description"
                    dense
                    outlined
                    type="text"
                    class="ml-sm-1"
                    :label="$t('medicalTests.description') + ' *'"
                    :error-messages="descriptionValidationError"
                    :disabled="isDisabled"
                  />
                </v-flex>
                <v-flex xs12 sm12 lg12>
                  <v-textarea
                    data-test="test-rx-input-observations"
                    v-model="medicalTest.observation"
                    dense
                    outlined
                    :label="$t('medicalTests.observations')"
                    :error-messages="observationValidationError"
                    :disabled="isDisabled"
                  />
                </v-flex>
                <v-flex xs12 sm12 lg12>
                  <template>
                    <v-toolbar dense flat>
                      <v-toolbar-title>{{ $t('medicalTests.tests_included') }}</v-toolbar-title>
                    </v-toolbar>
                    <div v-if="actListValidationError" class="v-messages theme--light error--text pl-4" role="alert">
                      <div class="v-messages__wrapper">
                        <div class="v-messages__message">{{ $t('medicalTests.almostOneAct') }}</div>
                      </div>
                    </div>
                    <v-layout v-for="(item, index) in medicalTest.acts" :key="index" wrap>
                      <v-flex xs12 sm3 lg2 class="text-center">
                        <span>{{ item.code }}</span>
                      </v-flex>
                      <v-flex xs12 sm8 lg9>
                        <span>{{ item.description }}</span>
                      </v-flex>
                      <v-flex xs12 sm1 lg1>
                        <div class="text-center">
                          <v-btn icon color="error" :disabled="submitting" @click="removeAct(index)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                    </v-layout>
                    <v-btn data-test="test-rx-button-add-act" color="primary" text :disabled="submitting" @click="addAct">
                      <v-icon left>
                        mdi-plus
                      </v-icon>
                      {{ $t('medicalTests.add_act') }}
                    </v-btn>
                  </template>
                </v-flex>
              </v-layout>
            </form>
          </v-card-text>
        </v-card>
        <div class="text-right mt-3">
          <v-btn data-test="test-rx-button-cancel" class="ma-2" color="primary" :disabled="isDisabled" outlined @click="cancel">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn data-test="test-rx-button-save" :disabled="isDisabled" :loading="submitting" color="primary" @click="save">
            {{ $t('common.save') }}
          </v-btn>
        </div>
      </v-card>
    </v-row>
    <v-dialog v-model="showActsDialog" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t('medicalTests.select_acts') }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="showActsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <ActSelector v-model="medicalTest.acts" class="mt-2" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getHeader, medicalTestsUrl } from '@/config/config';
import { medicaltestsNamePaths } from '../../../router/routes';
import { baseNameRoutes } from '../../../router/paths';

import useVuelidate from '@vuelidate/core';
import { required, maxLength, helpers, minLength } from '@vuelidate/validators';
import ActSelector from '@/components/MedicalTests/ActSelector';

const CODE_REGEX = /^(PA)[0-9]{4}$/;
const DESCRIPTION_MAX_LENGTH = 256;
const OBSERVATION_MAX_LENGTH = 1024;
const MEDICAL_TESTS_ENDPOINT = medicalTestsUrl + 'medical-order-templates';
const RX_TYPE = 'rx';

export default {
  components: { ActSelector },
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      loading: false,
      submitting: false,
      submittedWithErrors: false,
      medicalTest: {
        code: '',
        description: '',
        observation: '',
        acts: [],
      },
      isEdit: false,
      showActsDialog: false,
    };
  },

  validations() {
    return {
      medicalTest: {
        code: {
          required: helpers.withMessage(this.$t('common.obligatorio'), required),
          regexValidation: helpers.withMessage(this.$t('medicalTests.invalidCodeFormat'), helpers.regex(CODE_REGEX)),
          $autoDirty: true,
        },
        description: {
          required: helpers.withMessage(this.$t('medicalTests.description_too_short'), required),
          maxLength: helpers.withMessage(
            this.$t('errors.input_tam_maximo_superado', {
              input: this.$t('medicalTests.description'),
              tam: DESCRIPTION_MAX_LENGTH,
            }),
            maxLength(DESCRIPTION_MAX_LENGTH)
          ),
          $autoDirty: true,
        },
        observation: {
          maxLength: helpers.withMessage(
            this.$t('errors.input_tam_maximo_superado', {
              input: this.$t('medicalTests.observations'),
              tam: OBSERVATION_MAX_LENGTH,
            }),
            maxLength(OBSERVATION_MAX_LENGTH)
          ),
          $autoDirty: true,
        },
        acts: {
          minLength: minLength(1),
          $each: helpers.forEach({
            code: {
              required: helpers.withMessage(this.$t('common.obligatorio'), required),
            },
            description: {
              required: helpers.withMessage(this.$t('common.obligatorio'), required),
            },
          }),
        },
      },
    };
  },

  computed: {
    ...mapState({
      appDisplayName: state => state.app.displayName,
    }),

    isMobileVersion() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    codeValidationError() {
      return this.submittedWithErrors ? this.v$.medicalTest.code.$errors.map(error => error.$message) : null;
    },

    descriptionValidationError() {
      return this.submittedWithErrors ? this.v$.medicalTest.description.$errors.map(error => error.$message) : null;
    },

    observationValidationError() {
      return this.submittedWithErrors ? this.v$.medicalTest.observation.$errors.map(error => error.$message) : null;
    },

    actListValidationError() {
      return this.submittedWithErrors ? this.v$.medicalTest.acts.$errors.map(error => error.$message) : null;
    },

    isDisabled() {
      return this.submitting || this.loading;
    },
  },

  created() {
    this.isEditPage();
  },

  methods: {
    ...mapActions('layout', ['showAlert']),

    redirectToDashboard() {
      this.$router.push({ name: baseNameRoutes.dashboard });
    },

    redirectToMain() {
      this.$router.push({ name: medicaltestsNamePaths.rxProtocols });
    },

    cancel() {
      this.redirectToMain();
    },

    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },

    save() {
      this.v$.$touch();
      this.submitting = true;

      if (this.v$.$invalid) {
        this.submittedWithErrors = true;
        this.submitting = false;
        return;
      }

      if (this.isEdit) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      this.submitting = true;
      this.$http
        .post(
          MEDICAL_TESTS_ENDPOINT,
          {
            code: this.medicalTest.code,
            description: this.medicalTest.description,
            observation: this.medicalTest.observation,
            acts: this.medicalTest.acts.map(act => act.id),
            type: RX_TYPE,
          },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200) {
            this.showAlertMsg('success', 'mdi-check', this.$t('medicalTests.create_test_success'));
            this.delay(2000).then(() => this.redirectToMain());
          } else {
            this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.try_again'));
          }
          this.loading = false;
          this.submitting = false;
          this.redirectToMain();
        })
        .catch(() => {
          this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.try_again'));
          this.loading = false;
          this.submitting = false;
        });
    },

    update() {
      this.submitting = true;
      this.$http
        .put(
          `${MEDICAL_TESTS_ENDPOINT}/${this.medicalTest.id}`,
          {
            code: this.medicalTest.code,
            description: this.medicalTest.description,
            observation: this.medicalTest.observation,
            acts: this.medicalTest.acts.map(act => act.id),
          },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200) {
            this.showAlertMsg('success', 'mdi-check', this.$t('medicalTests.create_test_success'));
            this.delay(2000).then(() => this.redirectToMain());
          } else {
            this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.try_again'));
          }
          this.loading = false;
          this.submitting = false;
          this.redirectToMain();
        })
        .catch(() => {
          this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.try_again'));
          this.loading = false;
          this.submitting = false;
        });
    },

    isEditPage() {
      if (this.$route.params.id) {
        this.isEdit = true;
        this.getEditData();
      }
    },

    getEditData() {
      this.loading = true;
      this.$http
        .get(`${MEDICAL_TESTS_ENDPOINT}/${this.$route.params.id}`, { headers: getHeader() })
        .then(res => {
          this.medicalTest = res.data;
        })
        .catch(err => {
          if (err.status === 404) {
            this.goNotFound();
          } else {
            this.$toast.error(this.$t('errors.try_again'));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addAct() {
      this.showActsDialog = true;
    },

    removeAct(index) {
      this.medicalTest.acts.splice(index, 1);
    },

    showAlertMsg(color, icon, msg) {
      this.showAlert({
        color: color,
        icon: icon,
        message: msg,
      });
    },
  },
};
</script>
